<template>
  <div>
      <p class="title">
     <el-link type="primary" style="fontSize:20px"   :underline="false" @click="hpage">{{breadcr}}</el-link> >><span style="fontSize:16px"> {{breadcrs}}</span></p>
            <p style="textAlign:left;paddingLeft:100px;fontSize:23px;padding-top:30px">{{breadcrs}}</p>   
        <ul style="textAlign:left;padding-left:120px;width:100% ">
            <li v-for="(item,index) in options" :key="index" @click="to_list(item.brand_name,item.id)">{{item.brand_name}}</li>
        </ul>
      </div>
</template>

<script>
export default {
    inject:['chanlist'],
    name:'Breadcrumb',
    data() {
        return {
            breadcr:"",
            breadcrs:"",
            options:[
]
        }
    },
    mounted() {
        this.chanlist('1-2','1')
        if (this.$route.params.id==undefined) {
            this.$router.push("/homes/goods_order")
            return false
        }
        // console.log(this.$route.params.id);
    this.breadcr=this.$route.params.b
    this.breadcrs=this.$route.params.a
    this.getbrand()
    },
    methods: {
        hpage(){
            this.$router.push({path:"/homes/goods_order"})
        },
        to_list(a,id){
            this.$router.push({name:"Goods_list",params:{a,b:this.breadcrs,c:this.breadcr,pid:this.$route.params.id,id,ref:2}})
        },
        getbrand(){
            this.$axios.post("/supplier/good/list/brand",{type:3,pid:this.$route.params.id})
      .then((res)=>{
        // console.log(res.data.data);
        this.options=res.data.data
  })
        }
    },
}
</script>

<style lang="less" scoped>
.title{height: 40px;line-height: 40px;padding-left: 20px;border-bottom: 1px solid black;text-align: left;}
li{cursor: pointer;display:inline-block;min-width: 200px;line-height: 34px;font-size: 16px;font-family:Microsoft YaHei; margin:0 20px ;overflow:hidden;padding-top: 10px;}
</style>